/* Ensure the content area is centered but the header, nav, and footer take full width */
body {
  margin: 0;
  padding: 0;
  font-family: Arial, sans-serif;
  background-color: #ECEFF1;
}

/* Ensure header and footer span full width */
header, footer {
  background-color: #0288d1;
  color: white;
  padding: 20px;
  text-align: center;
  width: 100%;
}

/* Make the nav also span full width */
nav {
  background-color: #026aa7;
  padding: 10px;
  width: 100%;
  text-align: center;
}

/* Navigation menu styling */
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  gap: 20px;
}

nav ul li {
  display: inline;
}

nav ul li a {
  color: white;
  text-decoration: none;
  font-weight: bold;
}

/* Style the main content area */
#content {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Ensure each section is separated */
section {
  margin-bottom: 30px;
  padding: 20px;
  text-align: center;
}

a {
  text-decoration: none;
  color: #039be5;
}

a:hover {
  text-decoration: underline;
}
